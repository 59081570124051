import React, { useState } from "react";
import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";

import TextField from "components/Form/TextField/TextField";
import { sendGetInTouch } from "services/webBuilder";
import { store } from "react-notifications-component";
import ContactUsWithImage from "./ContachUsWithImage";
import Locale from "translations";
import { validateEmail, validateName } from "helpers/validate";
import { useWebBuilderState } from "context/webBuilder";
import ContactUsTheme4 from "./Theme4";
import ContactUsTheme10 from "./Theme10";

function ContactUs({ details, onFocus, focusContainer }) {
  const { style } = useWebBuilderState();
  const { webBuilder, errors } = Locale;
  const { id, body, title, withImage } = details;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    notes: "",
  });

  const [formError, setFormError] = useState(false);

  const handleFormData = ({ name, value }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNameKeyup = (event) => {
    if (!validateName(event.key)) {
      event.preventDefault();
    }
    setFormError(false);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    if (
      !validateEmail(formData.email) ||
      !formData.name ||
      !formData.phone ||
      formData.phone?.length < 5 ||
      !formData.notes
    ) {
      setFormError(true);
      return;
    }
    const data = {
      ...formData,
      first_name: formData.name,
      message: formData.notes,
    };
    if (process.env.REACT_APP_WebsiteName) {
      const res = await sendGetInTouch(process.env.REACT_APP_WebsiteName, data);
      if (res.status === 200) {
        store.addNotification({
          message: res?.data?.message || "Email Sent Successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setFormData({
          name: "",
          email: "",
          phone: "",
          notes: "",
        });
      }
    }
  };
  return (
    <section className="wb-contact-us">
      {style.theme === "theme-1" ||
        style.theme === "theme-2" ||
        style.theme === "theme-3" ||
        style.theme === "theme-5" ||
        style.theme === "theme-7" ? (
        !withImage ? (
          <div className="web-builder-content-contact-us" id={id}>
              <div className={`${style.theme === "theme-7" || style.theme === "theme-5" ? "custom-container" : ""}`}>
              <div className="contact-us-layout">
                <div className="content-top-section">
                  <p className="text-body">{body}</p>
                  <h4 className="h3 font-weight-bold mb-3 mt-1 text-capitalize">
                    {title}
                  </h4>
                </div>
                {formError && (
                  <div
                    className="alert alert-danger w-100 text-center"
                    style={{ maxWidth: "900px" }}
                  >
                    {errors.pleaseValidateDataBeforeSubmission}
                  </div>
                )}
                <form onSubmit={onSubmitForm} className="contact-us-form">
                  <div className="contact-us-fields">
                    <TextField
                      haslabel={false}
                      placeholder={webBuilder.name}
                      value={formData.name}
                      onChange={(e) =>
                        handleFormData({ name: "name", value: e.target.value })
                      }
                      onKeyPress={(event) => handleNameKeyup(event)}
                      isImage
                      image={ProfileIcon}
                      prependimage
                    />
                  </div>
                  <div className="contact-us-fields">
                    <TextField
                      haslabel={false}
                      placeholder={webBuilder.email}
                      type="text"
                      value={formData.email}
                      onChange={(e) =>
                        handleFormData({ name: "email", value: e.target.value })
                      }
                      onKeyUp={() => setFormError(false)}
                      isImage
                      image={SmsIcon}
                      prependimage
                    />
                  </div>

                  {/* phone  */}
                  <div className="contact-us-fields">
                    <TextField
                      type="phone"
                      haslabel={false}
                      id="form-phone"
                      name="form-phone"
                      placeholder={webBuilder.phone}
                      value={formData.phone}
                      isImage
                      image={MobileIcon}
                      prependimage
                      onChange={(e) =>
                        handleFormData({
                          name: "phone",
                          value: e.target.value.replace(/[^0-9]/g, ""),
                        })
                      }
                      onKeyUp={() => setFormError(false)}
                    />
                  </div>

                  <textarea
                    rows={5}
                    placeholder={webBuilder.message}
                    value={formData.notes}
                    onChange={(e) =>
                      handleFormData({ name: "notes", value: e.target.value })
                    }
                    onKeyUp={() => setFormError(false)}
                  />

                  <button type="submit" className="contact-us-submit-button">
                    {webBuilder.submit}
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <ContactUsWithImage
            details={details}
            onFocus={onFocus}
            focusContainer={focusContainer}
          />
        )
      ) : null}

      {style.theme === "theme-4" && (
        <ContactUsTheme4
          details={details}
          onSubmitForm={onSubmitForm}
          handleFormData={handleFormData}
          formData={formData}
          formError={formError}
        />
      )}
      {style.theme === "theme-10" && (
        <ContactUsTheme10
          details={details}
          onSubmitForm={onSubmitForm}
          handleFormData={handleFormData}
          formData={formData}
          formError={formError}
        />
      )}
    </section>
  );
}

export default ContactUs;
