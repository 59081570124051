import { CalenderSm, LocationIcon } from 'components/Icons';
import React, { useState } from 'react'
import { TabContent, TabPane } from 'reactstrap';
import { ReactComponent as MoonIcon } from "assets/images/market-place/moon-icon-outline.svg";
import moment from 'moment';
import Locale from 'translations';
import ImageGallery from './ImageGallery';
import placeholderSrc from "assets/images/placeHoleder.png"


export default function PackageHotelView({ hotelData }) {
  const { packages, productsBuilder, webBuilder } = Locale;
  const shortDescription = hotelData?.hotel?.description ? hotelData?.hotel?.description.substring(0, 255) : null;
  const [activeTab, setActiveTab] = useState("1");
  const [description, setDescription] = useState(shortDescription)
  const hotelRating = +hotelData?.hotel?.rating > 5 ? 5 : +hotelData?.hotel?.rating;
  
  const roomImages = Array.isArray(hotelData?.hotel?.images) &&
    hotelData?.hotel?.images?.length > 0
      ? hotelData?.hotel?.images?.map((i) => ({
        src: i,
        alt: i,
      }))
      : 
      [];

  return (
    <div className='package-view-container'>
      <div className="d-flex flex-wrap flex-md-nowrap">
        <div className="package-view-img">
          <img src={hotelData?.hotel?.image} alt={hotelData?.hotel?.name}
            onError={(e) => { e.target.src = placeholderSrc }}
          />
        </div>
        <div className="package-view-info mx-md-3">
          <h4 className='title'>{hotelData?.hotel?.name}</h4>
          <div className="rating">
            {[1, 2, 3, 4, 5]?.map(rate => {
              return (
                <span key={rate}>
                  {rate > hotelRating
                    ? <i className="far fa-star empty-rate" aria-hidden="true" key={rate} />
                    : <i className="fa fa-star" aria-hidden="true" key={rate} />}
                </span>
              )
            })}
          </div>
          {hotelData?.hotel?.location ?
            <div className='d-flex'>
              <LocationIcon />
              <span className='mx-1'>{hotelData?.hotel?.location}</span>
            </div>
            :
            null
          }

          <div className='d-flex flex-wrap align-items-center'>
            <p className='d-flex align-items-center'>
              <CalenderSm />
              <span className='mx-1'>{moment(hotelData?.check_in_at).format("DD MMM YYYY")}</span>
            </p>
            <p className='d-flex align-items-center mx-md-3'>
              <MoonIcon />
   
              <span className='mx-1'>{webBuilder.youWillStayHere} <b>{moment(hotelData?.check_out_at).add(1, "day").diff(moment(hotelData?.check_in_at), "days")}</b> {webBuilder.nights}</span>
            </p>
          </div>


        </div>
      </div>

      <ul className='package-view-tabs'>
        <li className={`${activeTab === "1" ? "active" : ""}`} onClick={() => setActiveTab("1")}>
          {productsBuilder.overview}
        </li>
        <li className={`${activeTab === "2" ? "active" : ""}`} onClick={() => setActiveTab("2")}>
          {packages.aboutHotel}
        </li>
        <li className={`${activeTab === "3" ? "active" : ""}`} onClick={() => setActiveTab("3")}>
          {packages.gallery}
        </li>
        {/* <li className={`${activeTab === "4" ? "active" : ""}`} onClick={() => setActiveTab("4")}>
          {packages.policies}
        </li> */}
      </ul>

      <TabContent activeTab={activeTab} >
        <TabPane tabId="1">
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            {roomImages?.slice(0, 3)?.map(img => {
              return (
                <div className="package-view-img w-25">
                  <img src={img?.src} alt={img?.alt}
                    onError={(e) => { e.target.src = placeholderSrc }}
                  />
                </div>
              )
            })}
            <span style={{ color: "rgba(var(--web-page-primary-color))" }} className='d-flex align-items-center pointer' role='button' onClick={() => setActiveTab("3")}>
              {packages.allPhotos}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className='mx-2 mt-1'>
                <path d="M14.43 6.01953L20.5 12.0895L14.43 18.1595M3.5 12.0895H20.33" stroke="rgba(var(--web-page-primary-color))" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>

          {description &&
            <div className='mt-2'>
              <h4 className='title text-uppercase mb-2'>{webBuilder.description}</h4>
              <p>
                {description}
              </p>
              {hotelData?.hotel?.description?.length > 255 ?
                <button className='btn more-details-btn p-0 font-16 text-yellow'
                  onClick={() => setDescription((prev) => prev.length > 255 ? shortDescription : hotelData?.hotel?.description)}
                >
                  {description.length > 255 ? productsBuilder.showLess : webBuilder.showMore}
                </button>
                :
                null
              }
            </div>
          }
        </TabPane>

        <TabPane tabId="2">
          <div className='mt-4'>
            <h4 className='title text-uppercase mb-2'>{webBuilder.description}</h4>
            <p>{hotelData?.hotel?.description}</p>
          </div>
        </TabPane>

        <TabPane tabId="3">
          <ImageGallery images={roomImages} />
        </TabPane>

        {/* <TabPane tabId="4">Policies</TabPane> */}

      </TabContent>


    </div>
  )
}
