import { useLocation, Link } from "react-router-dom";
import { useWebBuilderState } from "context/webBuilder";
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import AboutUs from "modules/WebBuilder-V2/Components/Content/AboutUs";
import { useSBSState } from "context/global";
import Locale from "translations";

export default function AboutusThemePage() {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const { content, pagesData } = useWebBuilderState();
  const location = useLocation();
  const link = true ? `/` : `/web-builder-v2/preview/`;
  const preview = !location.pathname.includes("edit");
  const aboutPageData = pagesData?.find((page) => page.id === "about-page");
  const aboutDetails = content?.find((item) => item?.type === "aboutUs");

  const mainHeader = webBuilder.aboutus || aboutPageData?.mainHeader[locale] || "";

  return (
    <div className="web-builder-preview  web-builder-page-contianer about-page">
      {/* <HeroHeader /> */}
      {aboutPageData && (
        <div
          className="web-builder-hero p-3"
          style={{
            backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${aboutPageData?.hero})`,
          }}
        >
          <div className="d-flex flex-column gap-24 hero-content">
            <h1>{mainHeader}</h1>
            {preview && (
              <div className="page-bread-crumb">
                <Link className="home" to={link}>
                  {webBuilder.home}
                </Link>
                <span>
                  {" "}
                  <ChevronDown />
                </span>
                <span className="search">
                  {mainHeader}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {/* content */}
      <div className="web-builder-content">
        {aboutDetails && <AboutUs details={aboutDetails} />}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
