// Translate
import Locale from "translations";
// Assets
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
// Hooks
import { Link } from "react-router-dom";
import { useSBSState } from "context/global";
// ------------------------------------------------------------------------------------------

export default function PackageCardTheme10(props) {
  const {
    image,
    name,
    stars,
    product_uuid,
    // hotelName,
    price,
    locationName,
    initialName,
    currency,
  } = props;
  const { webBuilder } = Locale;
  const { locale } = useSBSState();


  const packageUrl = `/package/${product_uuid}`;

  // -------- JSX Code --------
  return (
    <Link to={packageUrl} className="package-hotel-card">
      <div className={`card-img`}>
        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
            width={"100%"}
            src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
            alt="Safa Visa"
          />
        )}
      </div>
      {/* card-caption */}
      <div className="card-content">
        <h2 className="title">{name || initialName}</h2>
        {/* star */}
        <div className="rate">
          {[...Array(5)].map((i, index) =>
            index < stars ? (
              <GoldenStarIcon key={index} />
            ) : (
              <StarIcon key={index} />
            )
          )}
        </div>
        <p className="location">
          <LocationIcon />
          <span className="mx-2">{locationName?.[locale] || locationName}</span>
        </p>

        {/* price-info */}
        <div className="price">
          <span>{webBuilder.from}</span>
          <p className="amount"> {price}</p>
          <span className="currency">{currency}</span>
        </div>
      </div>
    </Link>
  );
}
