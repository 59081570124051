
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import PackageCardThemeFive from './PackageCard';


export default function OurPackagesThemeFive({ details }) {
  const { title, body, items } = details;

  return (
    <section className="wb-our-packages" id="packages-section">
      <div className="custom-container">
        <div className="service-top-section">
          <div className="section_heading w-100">
            <ServicesContent
              header={title}
              body={body}
            />
          </div>
        </div>

        <div className="packages-cards-container">
          {items?.map((item, index) => (
            <PackageCardThemeFive
              {...item}
              key={`${item.product_uuid}-${index}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
