import { useSBSState } from 'context/global';
import { useWebBuilderState } from 'context/webBuilder';
import { logoURL } from 'helpers/utils';
import { Link } from 'react-router-dom';
import Locale from 'translations';


// const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
const currentYear = new Date().getFullYear();


export default function FooterThemeTen({ focusContainer, handleFocus, socialMediaIcons }) {
	const { webBuilder } = Locale;
	const { footer: footerDetails, hero } = useWebBuilderState();
	const { locale } = useSBSState();

	// const services = ourServices?.services?.filter(service => service?.visible);
	const header = hero?.header;
	// const logoImage = isObject(header?.logo) ?
	// 	<img src={`${header?.logo[locale].includes("http") ? header?.logo[locale] : URI + "/" + header?.logo[locale]}`} alt="Logo" />
	// 	:
	// 	<img src={`${header?.logo[locale]}`} alt="Logo" />;




	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id)
			element?.scrollIntoView({ behavior: 'smooth' })
		}
	}


	return (
		<div className="custom-container">
			<div className="footer-content">
				{/* right section */}
				<div className="d-flex flex-column gap-24">

					<div className="logo-container">
						<Link to="/">
							<img
								className="footer-photo"
								src={logoURL(header?.logo, header?.logo?.[locale])}
								alt="Logo"
							/>
						</Link>
					</div>

					<div className="social-media" >
						<ul className="links">
							{footerDetails?.links?.map((link) => {
								if (link?.visible) {
									return (
										<div key={link?.id}>
											{/* <a href={link?.url} target="_blank" rel="noreferrer"> */}
											{socialMediaIcons[link?.id]}
											{/* </a> */}
										</div>
									);
								} else {
									return null;
								}
							})}
						</ul>
					</div>
				</div>

				<ul className='links-list'>
					<li className={'text-secondary-color'}
						onClick={() => scrollToSection('main-web-builder')}
					>
						{webBuilder.home}
					</li>

					<li className={'text-secondary-color'}
						onClick={() => scrollToSection('contact-us-section')}
					>
						{webBuilder.getHelp}
					</li>

					<li className={'text-secondary-color'}
						onClick={() => scrollToSection('our-service')}
					>
						{webBuilder.ourServices}
					</li>
				</ul>
			</div>
			<div className="footer-copy-right d-flex justify-content-center align-items-center">
				<p className="footer-text mt-1">
					{" "}
					{webBuilder.allRightsReserved} {" - "}
					<a
						href="https://safasoft.com/"
						target="_blank"
						rel="noreferrer"
						className="text-white"
					>
						{webBuilder.safasoft}
					</a>{" "}
					© {currentYear}
				</p>
			</div>
		</div>
	)
}