import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";

import TextField from "components/Form/TextField/TextField";
import { useLocation } from "react-router-dom";
import Locale from "translations";

export default function ContactUsTheme10({
  details,
  onSubmitForm,
  handleFormData,
  formData,
  formError
}) {
  const { webBuilder, errors } = Locale;
  const { id, body, title, image } = details;
  const location = useLocation();


  const preview = !location.pathname.includes("edit");

  const Image = image ? <img src={image} alt="Logo" /> : null;

  return (
    <div className="contact-container custom-container" id={id}>


      <div className="contact-form-holder">
        <div className="contact-us-image">{Image}</div>
        {formError && (
          <div
            className="alert alert-danger w-100 text-center"
            style={{ maxWidth: "900px" }}
          >
            {errors.pleaseValidateDataBeforeSubmission}
          </div>
        )}
        <form onSubmit={onSubmitForm} className="contact-us-form">

          <div className="content-top-section title-holder">
            <h4 className="h4 t-title">{title}</h4>
            <p className="text-body t-body">{body}</p>
          </div>


          <div className="contact-us-fields my-3">
            <TextField
              haslabel={false}
              placeholder={webBuilder.name}
              value={formData.name}
              onChange={(e) =>
                handleFormData({ name: "name", value: e.target.value })
              }
              isImage
              image={ProfileIcon}
              prependimage
            />
          </div>
          <div className="contact-us-fields mb-3">
            <TextField
              haslabel={false}
              placeholder={webBuilder.email}
              type="email"
              value={formData.email}
              onChange={(e) =>
                handleFormData({ name: "email", value: e.target.value })
              }
              isImage
              image={SmsIcon}
              prependimage
            />
          </div>

          {/* phone  */}
          <div className="contact-us-fields mb-3">
            <TextField
              type="number"
              hasLabel={false}
              id="form-phone"
              name="form-phone"
              placeholder={webBuilder.phone}
              value={formData.phone}
              isImage
              image={MobileIcon}
              prependimage
              onChange={(e) =>
                handleFormData({ name: "phone", value: e.target.value })
              }
            />
          </div>

          <textarea
            rows={5}
            placeholder={webBuilder.message}
            value={formData.notes}
            onChange={(e) =>
              handleFormData({ name: "notes", value: e.target.value })
            }
            className="textarea-field mb-3 w-100"
          />

          <button
            type={preview ? "submit" : "button"}
            className="btn submit-btn"
          >
            {webBuilder.submit}
          </button>
        </form>
      </div>
    </div>
  );
}
