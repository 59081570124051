import Locale from "translations";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { Link } from "react-router-dom";
import moment, { now } from "moment";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function PackageCardThemeFive(props) {
  const {
    image,
    name,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    price,
    locationName,
    initialName,
    currency,
  } = props;
  
  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  const packageUrl = `/package/${product_uuid}`;
  let rate = +stars && +stars > 5 ? 5 : +stars;

  return (
    <Link to={packageUrl} className="package-hotel-card">
      <span className="rate">
        <GoldenStarIcon />
        {rate}
      </span>
      <div className="card-img">
        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
            src="https://s3-alpha-sig.figma.com/img/a4c9/9d6c/eb4e350f82bf2a0b98b1b0bd670881b0?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IQ8njZBDtaGbFP4kCFcV3WOUBetwTXScRGoV~e8gduHF-emSiek~S1kLO7tHl3MkPtvrr-C5VDTm-CxZIGrAzVcXxGMNeFZoI~rku8TspVERIVtjD5Lza5DNgYltABv3qd4tTv6aJD0oHv-B9Z1WExR09TBX~PBxwKF1U67nvt~Z77ymwlCbOqJcbnS6KvE2Pwonc2VmDwC0zLLXunzIfIECA3JIqHMVt6l6ffvx-Hq64CFNke5BiKBYK2GvCx~oUYEIC2WFFpg-J48uSKKGlucTyXHLUTdiUqqWOHkb-9C077a7j1nzHsy70HxOqEh6yyRvHzOiUFrEsKSjuCYVnw__"
            alt="Safa Visa"
          />
        )}
      </div>

      <div className="card-content-top">
        {/* package name and location */}
        <div className="card-details">

          <h4 className="title">{name || initialName}</h4>
          <p className="location">
            <LocationIcon />
            <span className="mx-2">{locationName?.[locale] || locationName}</span>
          </p>
        </div>
        {/* package price */}
        <div>
          <span>{webBuilder.from}</span>
          <p className="price">
            <span className="amount">{exchangedPrice}</span> {" "}
            <span className="currency">{exchangeCurrency}</span>
          </p>
        </div>
      </div>
      <div className="card-content-bottom">
        <div className="d-flex align-items-center mb-2">
          <Hotelcon className="me-1" />
          {hotelName ? hotelName : " - "}
        </div>

        <div className="d-flex align-items-center mb-2">
          <CalendarIcon className="me-1" />
          <span>
            {`${moment(departure_date_at || now()).format(
              "DD MMM YYYY"
            )} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
          </span>
        </div>

      </div>
    </Link>
  );
}
