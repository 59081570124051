import { Link } from "react-router-dom";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";
import { useWebBuilderState } from "context/webBuilder";
import Locale from "translations";
import { useSBSState } from "context/global";
import { useHistory } from "react-router-dom";
import { isArray } from "lodash";

export default function HeaderThemeFive({
  nav,
  logoImage,
  handleOpenMobileNav,
  clickContactButton,
}) {
  // *** hooks
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const history = useHistory();
  const { hero } = useWebBuilderState();
  const header = hero?.header;

  return (
    <header className="wb-header">
      <div className="d-flex" style={{ gap: "42px" }}>
        {/* logo */}
        <div className="logo">
          <Link to="/">
            <img src={logoImage} alt="Logo" />
          </Link>
        </div>

        {/* nav */}
        {nav}
      </div>
      <div className="d-flex-language-contact d-flex align-items-center gap-10">
        <button
          className="contact-button mr-2"
          onClick={() => {
            // check if we inside view package page when click scroll back to preview page the scroll using useeffect
            history.push({ pathname: `/`, state: header?.contactUs?.id });
            clickContactButton();
          }}
        >
          {header?.contactUs?.name?.[locale] || header?.contactUs?.name}
        </button>
        <LanguageSwitcher show={false} />
        <CurrencySwitcher show={false} />

        <div className="d-flex align-items-center gap-10 call-us">
          <PhoneIcon color="#1e85ff" className="mirror-rtl" />
          <div className="webBuilder-contact d-flex flex-column ml-1">
            <p>{webBuilder.callUs}</p>
            <div className="body text-primary-color contact-phones">
              {isArray(header?.phoneNumber) ? (
                <>
                  {header?.phoneNumber?.[0] || header?.phoneNumber}
                  {header?.phoneNumber?.length > 1 && (
                    <i className="fas fa-chevron-down ms-1" />
                  )}
                  {header?.phoneNumber?.length > 1 && (
                    <p className="phone-list">
                      {header?.phoneNumber?.map((number, index) => {
                        if (index > 0) {
                          return (
                            <span key={`${number}-${index}`}>{number}</span>
                          );
                        }
                        return null;
                      })}
                    </p>
                  )}
                </>
              ) : (
                <p
                  className="body fw-700"
                  style={{ width: "160px", direction: "ltr" }}
                >
                  <a
                    className="text-primary-color hover"
                    href={`tel:${header?.phoneNumber?.split(" ")[0]}`}
                  >
                    {header?.phoneNumber?.split(" ")[0]}
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
        <button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
          <MenuIcon />
        </button>
      </div>
    </header>
  );
}
