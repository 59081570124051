import useExchangeRate from "hooks/useExchangeRate";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Locale from "translations";

const arrowIcon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9297 6.42969L20.9997 12.4997L14.9297 18.5697"
      stroke="#1E85FF"
      strokeWidth="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 12.5H20.83"
      stroke="#1E85FF"
      strokeWidth="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default function FlightServiceCardTheme4(props) {
  const { webBuilder } = Locale;
  const { product_uuid, name, price, flightItems, currency } = props;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  let flightDestinations = Array.isArray(flightItems) ? flightItems : [];


  return (
    <article className="flight-service-card">
      <div className="header">
        <h3>{name}</h3>
        {flightDestinations.length - 1 > 0 &&
          <div className="destination-count">
            <b>+{flightDestinations.length - 1}</b> Destination
            <i className="fas fa-chevron-down mt-1"></i>
            <div className="addition-destinations-list">
              {flightDestinations.length > 1 &&
                flightDestinations.slice(1).map((destination) => {
                  return (
                    <DestinationPort
                      fromPortCode={destination?.fromPort?.code}
                      fromCountry={destination?.fromCountry?.name}
                      toPortCode={destination?.toPort?.code}
                      toCountry={destination?.toCountry?.name}
                    />
                  );
                })}
            </div>
          </div>
        }
      </div>

      <div className="content d-flex flex-column justify-content-between">
        {/* destinations list */}
        <div className="destinations-list">
          <DestinationPort
            fromPortCode={flightDestinations[0]?.fromPort?.code}
            fromCountry={flightDestinations[0]?.fromCountry?.name}
            toPortCode={flightDestinations[0]?.toPort?.code}
            toCountry={flightDestinations[0]?.toCountry?.name}
          />

          {/* show number of destionations after first destionation */}
          {/* {flightDestinations.length > 1 && (
						<div className="addition-destinations">
							<span className="addition-destinations-count mx-3">
								+{flightDestinations.length - 1}
							</span>
						</div>
					)} */}
        </div>

        <div className="d-flex align-items-center justify-content-between mt-3 gap-10">
          {/* flight price */}
          <p className="price border-0 d-flex align-items-center">
            <span>{webBuilder.startFrom}</span>
            <br />
            <span className="amount mx-2">
              {exchangedPrice} {" "} {exchangeCurrency}
            </span>
          </p>
          {/* button */}
          <Link to={`/flight/${product_uuid}`} className="cta-btn">
            {webBuilder.viewDeals}
          </Link>
        </div>
      </div>
    </article>
  );
}

function DestinationPort({ fromPortCode, fromCountry, toPortCode, toCountry }) {
  return (
    <div className="destination-port">
      <p className="font-weight-bold">
        <span>({fromPortCode})</span>
        <br />
        <span className="port-country">{fromCountry}</span>
      </p>

      <span className="arrow">{arrowIcon}</span>

      <p className="font-weight-bold">
        <span>({toPortCode})</span>
        <br />
        <span className="port-country">{toCountry}</span>
      </p>
    </div>
  );
}
